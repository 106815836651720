import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import {useSelector} from "react-redux";
import jwtDecode from 'jwt-decode';
const communityApiBaseUrl = process.env.REACT_APP_API_COMM_URL;

const initialState = {
  isLoading: false,
  error: null,
  user: null,
};

const userCommSlice = createSlice({
  name: 'userComm',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },
    updateUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },
  },
});

export const {
  startLoading,
  hasError,
  getUserSuccess,
  updateUserSuccess,
} = userCommSlice.actions;

export default userCommSlice.reducer;

// Thunk to fetch user data by talentId
// export function fetchUserByTalentId(talentId) {
//   return async (dispatch) => {
//     dispatch(startLoading());
//     try {
//       console.log("Fetching user by talentId:", talentId);
//       const response = await axios.get(`https://api.community.qrata.ai/u/users/talentId/${talentId}`);
     
//       dispatch(getUserSuccess(response.data));
//     } catch (error) {
//       console.error('Error fetching user:', error);
//       const errorMessage = error.response?.data?.message || 'An error occurred while fetching the user data.';
//       dispatch(hasError(errorMessage));
//     }
//   };
// }

// export function getInitializeUserComm() {
//   return async (dispatch) => {
//     dispatch(startLoading());
//     console.log('startLoading dispatched');

//     const waitForAccessToken = () => {
//       return new Promise((resolve) => {
//         const interval = setInterval(() => {
//           const accessToken = localStorage.getItem('accessToken');
//           console.log('Checking for accessToken:', accessToken);
          
//           if (accessToken) {
//             clearInterval(interval);
//             resolve(accessToken);
//           }
//         }, 1000); // Check every 1 second
//       });
//     };

//     try {
//       const accessToken = await waitForAccessToken();
      

//       const talentId = jwtDecode(accessToken)._id;
    
      
//       // Fetch user data by talentId
//       const response = await axios.get(`${communityApiBaseUrl}/u/users/talentId/${talentId}`);
//       console.log('User data response:', response.data);
      
//       dispatch(getUserSuccess(response.data));
//       console.log("userC");
//     } catch (error) {
//       console.log("userT");
//       console.error('Error initializing userComm:', error);
//       const errorMessage = error.response?.data?.message || 'An error occurred while initializing userComm data.';
//       dispatch(hasError(errorMessage));
//     }
//   };
// }

const apiClient = axios.create({
  baseURL: communityApiBaseUrl,
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export function getInitializeUserComm() {
  return async (dispatch) => {
    dispatch(startLoading());

    const waitForAccessToken = () => {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          const accessToken = localStorage.getItem('accessToken');
          if (accessToken) {
            clearInterval(interval);
            resolve(accessToken);
          }
        }, 1000); // Check every 1 second
      });
    };

    try {
      const accessToken = await waitForAccessToken();
      const talentId = jwtDecode(accessToken)._id;

      // Fetch user data by talentId only after ensuring talentId is decoded
      let response = await apiClient.get(`/u/users/talentId/${talentId}`);
      dispatch(getUserSuccess(response.data));
    } catch (error) {
      console.error('Error initializing userComm:', error);

      // Handle 404 error specifically for user not found
      if (error.response && error.response.status === 404) {
        try {
          const token = localStorage.getItem('accessToken'); // Get the token from storage
          const talentId = jwtDecode(token)._id; // Decode talentId again for user creation

          // POST request to create the user
          await apiClient.post(`/u/users`, { talentId });

          // Fetch the user again after creating
          let response = await apiClient.get(`/u/users/talentId/${talentId}`);
          dispatch(getUserSuccess(response.data));
        } catch (createError) {
          console.error('Error creating user:', createError);
          const errorMessage = createError.response?.data?.message || 'An error occurred while creating the user.';
          dispatch(hasError(errorMessage));
        }
      } else {
        const errorMessage = error.response?.data?.message || 'An error occurred while fetching the user data.';
        dispatch(hasError(errorMessage));
      }
    }
  };
}



// export function getInitializeUserComm() {
//   return async (dispatch) => {
//     dispatch(startLoading());
//     console.log('startLoading dispatched');

//     const waitForAccessToken = () => {
//       return new Promise((resolve) => {
//         const interval = setInterval(() => {
//           const accessToken = localStorage.getItem('accessToken');
//           console.log('Checking for accessToken:', accessToken);
          
//           if (accessToken) {
//             clearInterval(interval);
//             resolve(accessToken);
//           }
//         }, 1000); // Check every 1 second
//       });
//     };

//     try {
//       const accessToken = await waitForAccessToken();
//       const talentId = jwtDecode(accessToken)._id;

//       // Fetch user data by talentId
//       let response = await axios.get(`${communityApiBaseUrl}/u/users/talentId/${talentId}`);
//       console.log('User data response:', response.data);
      
//       dispatch(getUserSuccess(response.data));
//       console.log("User data fetched successfully");
//     } catch (error) {
//       console.error('Error initializing userComm:', error);

//       // Check if the error response indicates the user was not found
//       if (error.response && error.response.data && error.response.data.message === "User not found") {
//         try {
//           console.log("User not found, creating new user with talentId:", talentId);
//           const token = localStorage.getItem('accessToken'); // Get the token from storage

//           const config = {
//             headers: {
//               'Authorization': `Bearer ${token}`
//             }
//           };
//           await axios.post(`${communityApiBaseUrl}/u/users`, { talentId }, config);
                
//           // Fetch the user again after creating
//           response = await axios.get(`${communityApiBaseUrl}/u/users/talentId/${talentId}`);
//           dispatch(getUserSuccess(response.data));
//           console.log("User created and fetched successfully");
//         } catch (createError) {
//           console.error('Error creating user:', createError);
//           const errorMessage = createError.response?.data?.message || 'An error occurred while creating the user.';
//           dispatch(hasError(errorMessage));
//         }
//       } else {
//         const errorMessage = error.response?.data?.message || 'An error occurred while fetching the user data.';
//         dispatch(hasError(errorMessage));
//       }
//     }
//   };
// }

export function fetchUserByTalentId(talentId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {

     
      let response = await axios.get(`${communityApiBaseUrl}/u/users/talentId/${talentId}`);
      dispatch(getUserSuccess(response.data));
    
    } catch (error) {
      console.error('Error fetching user:', error);

      // Check if the error response indicates the user was not found
      if (error.response && error.response.data && error.response.data.message === "User not found") {
        try {
         
          // Create the user if not found
         
          const token = localStorage.getItem('accessToken'); // Get the token from storage

    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };
   
          await apiClient.post(`${communityApiBaseUrl}/u/users`, { talentId } );

          // Fetch the user again after creating
          response = await axios.get(`${communityApiBaseUrl}/u/users/talentId/${talentId}`);
          dispatch(getUserSuccess(response.data));
        } catch (createError) {
          console.error('Error creating user:', createError);
          const errorMessage = createError.response?.data?.message || 'An error occurred while creating the user.';
          dispatch(hasError(errorMessage));
        }
      } else {
        const errorMessage = error.response?.data?.message || 'An error occurred while fetching the user data.';
        dispatch(hasError(errorMessage));
      }
    }
  };
}

// Thunk to fetch user data by userId
export function fetchUserById(userId) {
  return async (dispatch) => {
    dispatch(startLoading());
    
    try {
    
      const response = await axios.get(`${communityApiBaseUrl}/u/users/${userId}` );
      dispatch(getUserSuccess(response.data));
    } catch (error) {
      console.error('Error fetching user:', error);
      const errorMessage = error.response?.data?.message || 'An error occurred while fetching the user data.';
      dispatch(hasError(errorMessage));
    }
  };
}


export function updateUserTalent(talentId, data) {
  return async (dispatch) => {
    dispatch(startLoading());
  
    try {
      const formData = new FormData();

      for (const key in data) {
        if (data[key] !== undefined && data[key] !== null) {
          if (key === 'currentDesignation' && typeof data[key] === 'object') {
            formData.append(key, JSON.stringify(data[key])); // Serialize only if it's an object
          } else if (key === 'profile' && data[key] instanceof File) {
            formData.append(key, data[key]); // Handle profile picture
          } else {
            formData.append(key, String(data[key])); // Convert other values to strings safely
          }
        }
      }
      
 
      // Make the API call
      const response = await apiClient.put(
        `${communityApiBaseUrl}/u/users/talentId/${talentId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // Dispatch success action
      dispatch(updateUserSuccess(response.data));
      
    } catch (error) {
      console.error('Error updating user:', error);
      const errorMessage =
        error.response?.data?.message || 'An error occurred while updating the user data.';
      dispatch(hasError(errorMessage));
    }
  };
}

export function updateUser(userId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`${communityApiBaseUrl}/u/users/${userId}`);
      dispatch(updateUserSuccess(response.data));
    } catch (error) {
      console.error('Error updating user:', error);
      const errorMessage = error.response?.data?.message || 'An error occurred while updating the user data.';
      dispatch(hasError(errorMessage));
    }
  };
}


